<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">合同中心</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">合同归档</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 15px">
            <div title="合同名称" class="searchboxItem ci-full">
              <span class="itemLabel">合同名称:</span>
              <el-input
                v-model="contractName"
                clearable
                placeholder="请输入合同名称"
                size="small"
              ></el-input>
            </div>
            <div title="合同类型" class="searchboxItem ci-full">
              <span class="itemLabel">合同类型:</span>
              <el-select
                size="small"
                v-model="contractType"
                clearable
                placeholder="请选择合同类型"
              >
                <el-option
                  v-for="item in contractTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="签订机构" class="searchboxItem ci-full">
              <span class="itemLabel">签订机构:</span>
              <el-select
                size="small"
                v-model="signCompId"
                remote
                :remote-method="getCompanyList"
                filterable
                clearable
                placeholder="请至少输入两个字搜索"
              >
                <el-option
                  v-for="item in CompanyList"
                  :key="item.compId"
                  :label="item.compName"
                  :value="item.compId"
                ></el-option>
              </el-select>
            </div>

            <div title="代理商" class="searchboxItem ci-full">
              <span class="itemLabel">代理商:</span>
              <el-select
                size="small"
                v-model="agencyId"
                remote
                :remote-method="getAgencyList"
                filterable
                clearable
                placeholder="请至少输入两个字搜索"
              >
                <el-option
                  v-for="item in AgencyList"
                  :key="item.agencyId"
                  :label="item.agencyName"
                  :value="item.agencyId"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox" style="margin-bottom: 15px">
            <div title="档案编号" class="searchboxItem ci-full">
              <span class="itemLabel">档案编号:</span>
              <el-input
                v-model="certNo"
                clearable
                placeholder="请输入档案编号"
                size="small"
              ></el-input>
            </div>
            <div title="审批状态" class="searchboxItem ci-full">
              <span class="itemLabel"  >审批状态:</span>
              <el-select
                size="small"
                v-model="auditType"
                clearable
                placeholder="请选择审批状态"
              >
                <el-option
                  v-for="item in ApprovalStatusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="合同形式" class="searchboxItem ci-full">
              <span class="itemLabel">合同形式:</span>
              <el-select
                size="small"
                v-model="contractForm"
                clearable
                placeholder="请选择合同形式"
              >
                <el-option
                  v-for="item in contractFormList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="合同用途" class="searchboxItem ci-full">
              <span class="itemLabel">合同用途:</span>
              <el-select
                size="small"
                v-model="contractPurpose"
                clearable
                placeholder="请选择合同用途"
              >
                <el-option
                  v-for="item in contractPurposeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox">
            <div title="行政区划" class="searchboxItem ci-full">
              <span class="itemLabel">行政区划:</span>
              <el-cascader clearable filterable v-model="areaId" :options="areatreeList" :props="propsarea" size="small"></el-cascader>
            </div>
            <div title="签订日期" class="searchboxItem ci-full">
              <span class="itemLabel">签订日期:</span>
              <el-date-picker
                clearable
                size="small"
                v-model="signingTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
            <div class="df" style="padding-left: 10px">
              <el-button type="primary" class="bgc-bv" round @click="getData()"
                >查询</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                fixed
              />
              <el-table-column
                label="合同名称"
                align="left"
                show-overflow-tooltip
                prop="contractName"
                fixed
                min-width="180px"
              />
              <el-table-column
                label="合同类型"
                align="left"
                show-overflow-tooltip
                prop="contractType"
                width="80px"
              >
                <template slot-scope="scope">
                  {{
                    scope.row.contractType == "10"
                      ? "收款合同"
                      : scope.row.contractType == "20"
                      ? "付款合同"
                      : scope.row.contractType == "30"
                      ? "备案合同"
                      : "代理合同"
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="合同形式"
                align="left"
                show-overflow-tooltip
                prop="contractForm"
                min-width="100px"
              >
                <template slot-scope="scope">
                  {{$setDictionary("CONTRACT_FORM",scope.row.contractForm)}}
                </template>
              </el-table-column>
              <el-table-column
                label="合同用途"
                align="left"
                show-overflow-tooltip
                prop="contractPurpose"
                min-width="100px"
              >
                <template slot-scope="scope">
                  {{$setDictionary("CONTRACT_PURPOSE",scope.row.contractPurpose)}}
                </template>
              </el-table-column>
              <el-table-column
                label="签订机构"
                align="left"
                show-overflow-tooltip
                prop="signCompName"
                min-width="220px"
              />

              <el-table-column
                label="代理商"
                align="left"
                show-overflow-tooltip
                prop="agencyName"
                min-width="220px"
              />
              <el-table-column
                label="行政区划"
                align="left"
                show-overflow-tooltip
                prop="areaNamePath"
                min-width="220px"
              /> 
              <el-table-column
                label="签订日期"
                align="left"
                show-overflow-tooltip
                prop="signDate"
                min-width="100px"
              >
                <template slot-scope="scope">
                  {{ scope.row.signDate | momentDate }}
                </template>
              </el-table-column>
              <el-table-column
                label="对接业务员"
                align="left"
                show-overflow-tooltip
                prop="saleName"
                min-width="100px"
              />
              <el-table-column
                label="最新负责人"
                align="left"
                show-overflow-tooltip
                prop="turnOverSaleName"
                min-width="100px"
              />
              <el-table-column
                label="经办人"
                align="left"
                show-overflow-tooltip
                prop="operator"
                min-width="100px"
              />
              <el-table-column
                label="合同状态"
                align="left"
                show-overflow-tooltip
                prop="createTime"
                min-width="80px"
              >
                <template slot-scope="scope">
                  {{
                    scope.row.settleState == "10"
                      ? "未开始"
                      : scope.row.settleState == "20"
                      ? "有效"
                      : "已过期"
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="审批状态"
                align="left"
                show-overflow-tooltip
                prop="auditType"
                min-width="120px"
              >
                <template slot-scope="scope">
                  {{
                    $setDictionary(
                      "FD_CONTRACT_AUDIT_TYPE",
                      scope.row.auditType
                    )
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="报备日期"
                align="left"
                show-overflow-tooltip
                prop="filingDate"
                min-width="150px"
              >
                <template slot-scope="scope">
                  {{ scope.row.filingDate | moment }}
                </template>
              </el-table-column>
              <el-table-column
                label="审核人"
                align="left"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ scope.row.reviewer }}
                </template>
              </el-table-column>
              <el-table-column
                label="审批人"
                align="left"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ scope.row.approver }}
                </template>
              </el-table-column>
              <el-table-column
                label="签章类型"
                align="left"
                show-overflow-tooltip
                prop="signatureType"
                width="120px"
              >
                <template slot-scope="scope">
                  {{
                    $setDictionary("SIGNATURE_TYPE", scope.row.signatureType)
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="签章日期"
                align="left"
                show-overflow-tooltip
                prop="signatureDate"
                width="100px"
              >
                <template slot-scope="scope">
                  {{ scope.row.signatureDate | momentDate }}
                </template>
              </el-table-column>
              <el-table-column
                label="档案编号"
                align="left"
                show-overflow-tooltip
                prop="certNo"
                min-width="140px"
              />
              <el-table-column
                label="归档日期"
                align="left"
                show-overflow-tooltip
                prop="archiveDate"
                width="100px"
              >
                <template slot-scope="scope">
                  {{ scope.row.archiveDate | momentDate }}
                </template>
              </el-table-column>
              <el-table-column
                label="财务备注"
                align="left"
                show-overflow-tooltip
                prop="financialRemark"
                min-width="220px"
              />
              <el-table-column
                label="操作"
                align="center"
                width="180px"
                fixed="right"
              >
                <div slot-scope="scope">
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    @click="editModel(scope.row.contractId,scope.row.isApproval)"
                    >查看</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    @click="
                      signatureOrSealFiling(scope.row.contractId, 'signature',scope.row)
                    "
                    >

<!--                    :disabled="scope.row.auditType !='70' && scope.row.auditType !='80' && scope.row.auditType !='90'"-->
                    签章</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    @click="
                      signatureOrSealFiling(scope.row.contractId, 'sealFiling')
                    "
                    >归档</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
      :title="fillTitle"
      :visible.sync="dialogFormVisible"
      @close="closeModel"
    >
      <el-form
        :model="groupForm"
        style="width: 100%"
        :rules="rules"
        ref="groupForm"
      >
        <el-form-item
            label="合同类型："
            label-width="100px"
            prop="contractType"
            v-if="type == 'signature'"
        >
          <el-select
              size="small"
              v-model="groupForm.contractType"
              clearable
              disabled
              placeholder="请选择合同类型"
          >
            <el-option
                v-for="item in contractTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="签章类型："
          label-width="100px"
          prop="signatureType"
          v-if="type == 'signature'"
        >
          <el-select
            size="small"
            v-model="groupForm.signatureType"
            clearable
            placeholder="请选择签章类型"
          >
            <el-option
              v-for="item in SignatureList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="档案编号："
          label-width="100px"
          v-if="type == 'sealFiling'"
          prop="certNo"
        >
          <el-input v-model="groupForm.certNo" size="small"></el-input>
        </el-form-item>
        <el-form-item
          :label="timeTitle + '：'"
          label-width="100px"
          prop="signingDate"
        >
          <div class="df">
            <el-date-picker
              clearable
              size="small"
              v-model="groupForm.signingDate"
              type="date"
              placeholder="请选择日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </div>
        </el-form-item>
        <el-form-item label="备注：" label-width="100px">
          <el-input type="textarea" v-model="groupForm.remark"></el-input>
        </el-form-item>
      </el-form>
      <h3 style="padding-bottom: 20px">审核记录</h3>
      <div>
        <el-table
          ref="multipleTable"
          :data="listData"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="tableHeader"
          stripe
        >
          <el-table-column label="序号" type="index" width="100" />
          <el-table-column
            label="操作内容"
            align="center"
            show-overflow-tooltip
            prop="auditType"
          >
            <template slot-scope="scope">
              {{
                $setDictionary("FD_CONTRACT_AUDIT_TYPE", scope.row.auditType)
              }}
            </template>
          </el-table-column>

          <el-table-column
            label="操作人"
            align="center"
            show-overflow-tooltip
            prop="fullname"
          />
          <el-table-column
            label="操作日期"
            align="center"
            show-overflow-tooltip
            prop="createTime"
          />
          <el-table-column
            label="备注"
            align="center"
            show-overflow-tooltip
            prop="remark"
          >
            <template slot-scope="scope">
              {{ scope.row.remark || "--" }}
            </template>
          </el-table-column>
          <el-table-column
              label="操作"
              align="center"
              width="180px"
              fixed="right"
              v-if="type == 'signature'"
          >
            <div slot-scope="scope">
              <el-button
                  type="text"
                  size="mini"
                  style="padding: 0 5px"
                  @click="editSignatureRecordDesc(scope.row)"
                  :disabled="scope.row.auditType!='80'">
                修改
              </el-button>
              <el-button
                  type="text"
                  size="mini"
                  style="padding: 0 5px"
                  @click="deleteSignatureRecord(scope.row)"
                  :disabled="scope.row.auditType!='80'">
                删除
              </el-button>
            </div>
          </el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="dialog-footer flexcc">
        <el-button @click="closeModel">取 消</el-button>
        <el-button class="bgc-bv" type="primary" @click="doOk('groupForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
        title="修改签章审核记录"
        :visible.sync="editSignatureVisible"
        @close="closeModelSignature"
    >
      <el-form
          :model="recordForm"
          style="width: 100%"
          :rules="recordRules"
          ref="recordForm"
      >
        <el-form-item label="备注：" label-width="100px" prop="remark">
          <el-input type="textarea" v-model="recordForm.remark"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer flexcc">
        <el-button @click="closeModelSignature">取 消</el-button>
        <el-button class="bgc-bv" type="primary" @click="recordSave"
        >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "evaluate/contractManagement",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      contractName: "", //合同名称
      contractType: "", //合同类型
      contractForm: "", //合同形式
      contractPurpose: "", //合同用途
      signingTime: "", //签订日期
      signCompId: "", //签订机构Id
      certNo:'', //档案编号
      auditType:'', //审批状态
      areaId:"", //行政区划
      areatreeList: [], // 行政区划
      // 行政区划 - 数据格式
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      CompanyList: [],
      contractTypeList: [
        {
          label: "收款合同",
          value: "10",
        },
        {
          label: "付款合同",
          value: "20",
        },
        {
          label: "备案合同",
          value: "30",
        },
        {
          label: "代理合同",
          value: "40",
        },
      ],
      type: "",
      fillTitle: "签章",
      timeTitle: "签章日期",
      dialogFormVisible: false,
      groupForm: {
        signatureType: "",
        contractType: "",//合同类型
        certNo: "",
        signingDate: "",
      },
      rules: {
        signatureType: [
          { required: true, message: "请选择签章类型", trigger: "blur" },
        ],
        certNo: [
          { required: true, message: "请输入档案编号", trigger: "blur" },
        ],
        signingDate: [
          { required: true, message: "请选择日期", trigger: "change" },
        ],
      },
      SignatureList: [], //签章类型
      ContractList: [], //合同类型
      listData: [], //审核记录
      ApprovalStatusList:[], //审批状态

      editSignatureVisible: false,
      recordForm: {
        remark: "",
      },
      recordRules: {
        remark: [
          { required: true, message: "请输入备注", trigger: "blur" },
        ]
      },
    //   代理商数据
      AgencyList: [],
      agencyId:"",//代理商id
      contractFormList: [],// 合同形式
      contractPurposeList: [],// 合同用途
    };
  },
  watch: {},
  created() {},
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser",
    }),
  },
  mounted() {
    this.getareatree();
    this.getCodeValue();
    this.getTableHeight();
    this.getcontractFormList();
  },
  methods: {
    // 获取行政区划
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    // 获取合同形式、合同用途字典
    getcontractFormList() {
      const list1 = this.$setDictionary("CONTRACT_FORM", "list");
      let contractFormList = [];
      for (const key in list1) {
        contractFormList.push({
          value: key,
          label: list1[key]
        });
      }
      this.contractFormList = contractFormList;
      const list2 = this.$setDictionary("CONTRACT_PURPOSE", "list");
      let contractPurposeList = [];
      for (const key in list2) {
        contractPurposeList.push({
          value: key,
          label: list2[key]
        });
      }
      this.contractPurposeList = contractPurposeList;
    },
    // 修改签章审核记录备注
    editSignatureRecordDesc(row){
      this.editSignatureVisible = true;
      this.recordForm = {
        ...row
      }
    },
    //保存修改记录
    recordSave(){
      this.$refs['recordForm'].validate((valid) => {
        if (valid) {
          const params = {
            ...this.recordForm
          };
          this.$post(
              "/biz/contractAudit/signatureModify",
              params
          ).then((ret) => {
            this.$message({
              message: "修改成功",
              type: "success",
            });
            this.getReviewList(this.contractId);
            // this.signatureOrSealFiling(this.recordForm.contractId,'signature',this.recordForm)
            this.closeModelSignature();
          });
        }
      });
    },
    // 关闭修改签章审核记录备注
    closeModelSignature() {
      this.recordForm = {
        remark: "",
      };
      this.editSignatureVisible = false;
    },
    // 删除签章审核记录
    deleteSignatureRecord(row){
      this.$confirm('确定删除此条审核记录吗', "提示", {
        confirmButtonText: "确定删除",
        cancelButtonText: "取消",
        confirmButtonClass: "bgc-bv",
      }).then(() => {
        let parmar = {
          ...row
        }
        this.$post("/biz/contractAudit/signatureDelete", parmar)
            .then((ret) => {
              if (ret.status == "0") {
                this.$message({
                  message: "删除成功",
                  type: "success",
                })
                this.getReviewList(this.contractId);
              }
            })
        .catch((err) => {});
      });
    },
    // 获取 - 代理商
    getAgencyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/biz/mercantile/agency/getListByName", {
          agencyName: query,
        })
          .then((res) => {
            if (res.status == 0) {
              this.AgencyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.AgencyList = [];
      }
    },
    /* 机构名称 */
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.contractName) {
        params.contractName = this.contractName;
      }
      if (this.contractType) {
        params.contractType = this.contractType;
      }
      if (this.contractForm) {
        params.contractForm = this.contractForm;
      }
      if (this.contractPurpose) {
        params.contractPurpose = this.contractPurpose;
      }
      if (this.signCompId) {
        params.signCompId = this.signCompId;
      }
      if (this.auditType) {
        params.auditType = this.auditType;
      }
      if (this.certNo) {
        params.certNo = this.certNo;
      }
      if (this.signingTime) {
        params.signStartDate = this.signingTime[0];
        params.signEndDate = this.signingTime[1];
      }
      if (this.agencyId) {
        params.agencyId = this.agencyId;
      }
      if(this.areaId){
        params.areaId=this.areaId;
      }
      this.doFetch({
        url: "/biz/new/bill/contract/pageList",
        params,
        pageNum,
      });
    },
    //编辑
    editModel(contractId, isApproval) {
      this.$router.push({
        path: "/web/financialSettlement/contractManagementAdd",
        query: {
          contractId,
          isApproval,
          stu: "look",
        },
      });
    },
 
    //签章or归档
    signatureOrSealFiling(contractId, type, row) {
      this.contractId = contractId;
      this.type = type;
      if (type == "signature") {
        this.fillTitle = "签章";
        this.timeTitle = "签章日期";
        if(row){
          this.getSignatureEcho(contractId, row.contractType);
        }

      } else {
        this.fillTitle = "归档";
        this.timeTitle = "归档日期";
        this.getArchiveEcho(contractId)
      }
      this.dialogFormVisible = true;
      this.groupForm.signingDate = this.$moment(new Date()).format(
        "YYYY-MM-DD"
      );
      this.getReviewList(contractId);
    },
    //签章回显
    getSignatureEcho(contractId,contractType) {
      this.$post("/biz/contractAudit/signatureEcho", { contractId }).then(
        (res) => {
          if (res.status == 0) {
            this.groupForm = {
              ...res.data,
              signingDate: res.data.signatureDate ? res.data.signatureDate.replaceAll("/", "-") :this.$moment(new Date()).format("YYYY-MM-DD"),
              contractType: contractType
            };

          }
        }
      );
    },
    //归档回显
    getArchiveEcho(contractId) {
      this.$post("/biz/contractAudit/archiveEcho", { contractId }).then(
        (res) => {
          if (res.status == 0) {
            this.groupForm = {
              ...res.data,
              signingDate: res.data.archiveDate ? res.data.archiveDate.replaceAll("/", "-"):this.$moment(new Date()).format(
        "YYYY-MM-DD"
      ),
            };
          }
        }
      );
    },
    //弹框关闭
    closeModel() {
      this.groupForm = {
        signatureType: "",
        certNo: "",
        signingDate: "",
      };
      this.dialogFormVisible = false;
    },
    //弹框确定
    doOk(groupForm) {
      this.$refs[groupForm].validate((valid) => {
        if (valid) {
          const params = {
            contractId: this.contractId,
            remark: this.groupForm.remark,
          };
          if (this.type == "signature") {
            params.signatureType = this.groupForm.signatureType;
            params.signatureDate = this.groupForm.signingDate;
          } else {
            params.archiveDate = this.groupForm.signingDate;
            params.certNo = this.groupForm.certNo;
          }
          this.$post(
            this.type == "signature"
              ? "/biz/contractAudit/signature"
              : "/biz/contractAudit/archive",
            params
          ).then((ret) => {
            this.closeModel();
            this.getData(-1);
          });
        }
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 4.5) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
      // // 40为thead高 | trHeight默认45为带按钮的tbody的tr高
      // this.pageSize = Math.ceil((tHeight -40) / trHeight);
    },
    getCodeValue() {
      const SignatureList = this.$setDictionary("SIGNATURE_TYPE", "list");
      for (const key in SignatureList) {
        this.SignatureList.push({
          value: key,
          label: SignatureList[key],
        });
      }
       const ApprovalStatus = this.$setDictionary(
        "FD_CONTRACT_AUDIT_TYPE",
        "list"
      );
      for (const key in ApprovalStatus) {
        this.ApprovalStatusList.push({
          value: key,
          label: ApprovalStatus[key],
        });
      }
    },
    //审核记录
    getReviewList(contractId) {
      this.$post("/biz/contractAudit/pageList", { contractId }).then((ret) => {
        if (ret.status == 0) {
          this.listData = ret.data;
        }
      });
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    // 监听缓存后调用接口
    $route: {
      handler: function (route) {
        if (route.query.refresh == true) {
          this.getData(-1);
        }
        // this.activeName = route.params.refName;
      },
      deep: true,
    },
  },
};
</script>
<style lang="less" scope>
.el-tooltip__popper {
  max-width: 45% !important;
}
</style>
